import React from 'react';
import Container from './Container';
import ImageText from './ImageText';
import ImageTextDescription from './ImageTextDesctiption';
import Financial1 from '../images/financial-1.svg';
import Financial2 from '../images/financial-2.svg';
import Financial3 from '../images/financial-3.svg';

export default () => {
  return (
    <div className="content-list-wrapper">
      <section className="image-text-section white mini-padding">
        <Container>
          <ImageText image={Financial1} imgWidth={476} imgHeight={383} title="银行业营销场景" imageSize={10}>
            <ImageTextDescription
              label="客户旅程优化"
              content="客户代发、财富理财、存款和线下柜台的全生命周期旅程设计及优化"
            />
            <ImageTextDescription
              label="客户分层运营"
              content="将不同的客户类型，按照区域、性别、产品、客户价值维度分层运营"
            />
            <ImageTextDescription
              label="客户留存运营"
              content="针对新客、代发和VIP客户的留存运营模型，有效降低迁徙率"
            />
            <ImageTextDescription
              label="非管户运营"
              content="针对低效非管户的运营和激活，采用权益及产品推荐的激活场景"
            />
            <ImageTextDescription
              label="智能化产品推荐"
              content="AI推荐模型为各类场景提供精准的金融产品推荐，提升AUM指标"
            />
            <ImageTextDescription
              label="信用卡营销"
              content="围绕用户触达、开卡、激活和活卡等场景，有效提升发卡量和激活率"
            />
          </ImageText>
        </Container>
      </section>
      <section className="image-text-section blue mini-padding">
        <Container>
          <ImageText image={Financial2} imgWidth={429} imgHeight={415} title="保险行业营销场景" position="right" imageSize={10}>
            <ImageTextDescription
              label="裂变和全员推广"
              content="通过赋能保险代理人，增加拓客渠道，降低获客成本"
            />
            <ImageTextDescription
              label="客户留存运营"
              content="通过自动化个性化的营销手段，提高续保率，降低退保率"
            />
            <ImageTextDescription
              label="智能产品推荐"
              content="根据投保信息、历史保单及行为数据，智能推送意向险种"
            />
            <ImageTextDescription
              label="内容营销管理"
              content="基于精准用户画像的内容推送，提高沟通体验，降低反感"
            />
            <ImageTextDescription
              label="交叉营销"
              content="通过全渠道客户数据的打通，精准了解客户投保情况，促进交叉营销"
            />
            <ImageTextDescription
              label="线上投保营销"
              content="搭建覆盖广告投放、CDP平台和营销管理的线上投保营销体系"
            />
          </ImageText>
        </Container>
      </section>
      <section className="image-text-section white mini-padding">
        <Container>
          <ImageText image={Financial3} imgWidth={466} imgHeight={427} title="证券基金营销场景" imageSize={10}>
            <ImageTextDescription
              label="拉新引流获客"
              content="全渠道营销推动低成本获客，提高新客开户量和开户完成率"
            />
            <ImageTextDescription
              label="沉睡客户唤醒"
              content="通过自动化营销唤醒老客户和新开非有效户，增加经纪业务收入"
            />
            <ImageTextDescription
              label="直播营销"
              content="通过直播互动提高股民黏性和活跃度，与地推开户结合提升业绩"
            />
            <ImageTextDescription
              label="长尾客户关怀和运营"
              content="基于精准的客户画像与长尾客户进行营销触达和客户关怀"
            />
          </ImageText>
        </Container>
      </section>
    </div>
  );
};
