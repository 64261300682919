import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import Layout from '../components/Layout';
import CommonPageBanner from '../components/CommonPageBanner';
import TitledVerticalBoxes from '../components/TitledVerticalBoxes';
import FinancialContentList from '../components/FinancialContentList';
import CustomerCase from '../components/CustomerCase';
import DigitalTransformation from '../components/DigitalTransformation';
import VerticalBox from '../components/VerticalBox';
import TitleWithBgSection from '../components/TitleWithBgSection';
import IconText from '../components/IconText';
import SpdBank from '../images/spd-bank.svg';

function CustomIconText({ title, iconId, content }) {
  return (
    <Col xl={6} sm={12} className="icon-text-col">
      <IconText title={title} content={content} iconId={iconId} iconSize={50} iconPosition="top" />
    </Col>
  );
}

CustomIconText.propTypes = {
  title: PropTypes.string,
  iconId: PropTypes.string,
  content: PropTypes.string,
};

export default () => (
  <Layout current="solutions" subCurrent="financial">
    <CommonPageBanner
      title="金融行业解决方案"
      description="金融业务线上化打破了业务增长与网点覆盖率的关系，随着金融行业市场化的提高和竞争的加剧，金融机构开始重视线上营销渠道、数据的整合与变现、客户运营和用户画像的创建，金融行业迎来了数字化营销的大变革。"
      imgName="financial"
    />
    <TitledVerticalBoxes title="金融行业面临的普遍问题" shadowed>
      <Col className="icon-text-col" lg={8} md={12} xs={12}>
        <VerticalBox iconIndex={1} description="业绩增长放缓获客成本提高" />
      </Col>
      <Col className="icon-text-col" lg={8} md={12} xs={12}>
        <VerticalBox iconIndex={2} description="缺乏对客户画像的有效洞察" />
      </Col>
      <Col className="icon-text-col" lg={8} md={12} xs={12}>
        <VerticalBox iconIndex={3} description="营销活动易受抵触和反感" />
      </Col>
      <Col className="icon-text-col" lg={8} md={12} xs={12}>
        <VerticalBox iconIndex={4} description="大规模营销活动执行困难" />
      </Col>
      <Col className="icon-text-col" lg={8} md={12} xs={12}>
        <VerticalBox iconIndex={5} description="营销行为经常遇到风控问题" />
      </Col>
      <Col className="icon-text-col" lg={8} md={12} xs={12}>
        <VerticalBox iconIndex={6} description="客户担心个人数据的安全" />
      </Col>
    </TitledVerticalBoxes>
    <DigitalTransformation
      title="Convertlab帮助金融行业实现数字化营销转型"
      boxList={[
        { id: '1', title: '全渠道运营', content: '线上线下全渠道触点的协同和全渠道客户数据的统一管理' },
        { id: '2', title: '数据驱动运营', content: '建立统一的CDP客户数据平台，形成丰富的扩展应用' },
        { id: '3', title: '场景化运营', content: '通过游戏化场景、旅程化场景实现拉新、促活及客户转化' },
        { id: '4', title: '自动化运营', content: '支持主动、被动、流批一体的MA营销自动化策略搭建' },
        { id: '5', title: 'AI智能运营', content: 'Ad Hub智能营销模块赋能产品推荐、智能风控等AI应用' },
        { id: '6', title: '精细化运营', content: '基于精准客户画像的个性化沟通，提升客户体验和满意度' },
      ]}
    />
    <TitleWithBgSection
      title="企业级数据安全能力保障"
      description="Convertlab的技术架构充分考虑了数据安全和信息保护前提下超大规模营销应用需求"
      gutter={30}
    >
      <>
        <CustomIconText
          title="数据合规性"
          content="符合SaaS安全性合规和GDPR合规，并获得ISO 27001 国际信息安全认证"
          iconId="iconshield"
        />
        <CustomIconText
          title="关键信息加密"
          content="对属于个人信息保护条例规定的信息（手机号码、地址等）提供存储层的数据加密"
          iconId="iconlock"
        />
        <CustomIconText
          title="多级数据权限"
          content="完整的多级数据权限控制，可以支持总部、区域、分公司等多级别独立控制数据权限"
          iconId="icondata"
        />
        <CustomIconText
          title="支持私有化部署"
          content="支持公有云、私有云、混合云等多种部署模式，根据数据安全需要可灵活部署"
          iconId="iconblocks"
        />
      </>
    </TitleWithBgSection>
    <FinancialContentList />
    <CustomerCase
      title="金融行业客户案例"
      icon={SpdBank}
      description="上海浦东发展银行股份有限公司（以下简称:浦发银行）是1992年经中国人民银行批准设立，并于1999年在上海证券交易所挂牌上市的全国性股份制商业银行，总行设在上海。目前，注册资本金293.52亿元。良好的业绩、诚信的声誉，使浦发银行成为中国证券市场中备受关注和尊敬的上市公司。至2019年6月末，公司总资产规模达6.66万亿元。目前，浦发银行已在境内外设立了41家一级分行、近1700家营业机构，拥有超过5万名员工，架构起全国性国际化商业银行的经营服务格局。"
      challenges={[
        {
          id: 'icon-1',
          iconIndex: 7,
          description: '浦发零售银行业务共有近亿用户，整体业绩增长放缓。',
        },
        {
          id: 'icon-2',
          iconIndex: 8,
          description: '银行庞大的用户但大多数活跃度偏低，需激活用户提高业绩增长。',
        },
        {
          id: 'icon-3',
          iconIndex: 14,
          description: '运营人员数量有限，无法高效的对千万数量级用户进行精细化运营。',
        },
      ]}
      solutions={[
        '在浦发现有数据管理平台之上，通过 DM Hub 产品搭建互联网营销运营平台。',
        '营销运营平台形成数据驱动的自动化精准营销能力，持续不间断促活用户。',
        '提供符合银行特点的活动管理和内容管理功能，实现无需技术参与即可快速上线各类活动。',
        '用户分层分群，针对不同客群采取不同营销策略，推送不同活动，以达到提高用户活跃、活动参与，进而提升核心业务指标。',
      ]}
      effects={[
        {
          id: 'icon-1',
          iconId: 'iconeffect-1',
          content: '一体化数字营销平台大幅提高活动准备效率，运营团队具备随时上线活动的能力，从活动准备到上线不需技术参与，可由运营团队自主完成，一周内已可同时举办多场活动。',
        },
        {
          id: 'icon-2',
          iconId: 'iconeffect-2',
          content: '基于标签进行用户个性化分群分层，结合个性化内容触达，大幅提升活动参与率，并提高核心业务指标AUM。',
        },
        {
          id: 'icon-3',
          iconId: 'iconeffect-3',
          content: '某次百万级高潜力用户的营销活动，共获得百亿级净资产提升。其中通过对比组验证，由线上自动化精准营销直接带来超过30%的业绩提升。',
        },
      ]}
    />
  </Layout>
);
